<template>
	<div id="app">

		<router-view :class="`deviece_is_${deviceType}`" />

	</div>
</template>


<script>
export default {
	name: "app",
	computed: {
		deviceType() {
			// 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
			if (this.$ssrContext) {
				return this.$ssrContext.deviceType;
			}

			// 在客户端渲染期间，使用窗口宽度检测设备类型
			const windowWidth =
				window.innerWidth || document.documentElement.clientWidth;
			return windowWidth < 768 ? "mobile" : "desktop";
		},
		isMobile() {
			return this.deviceType === "mobile";
		},
	},
	created() {
		if (process.env.NODE_ENV === "development") {
			let device = this.$ssrContext
				? this.$ssrContext.device
				: window["__INITIAL_STATE__"];
			console.log(`deviece App--> ${device}`);
		}
	},
	mounted() {
		console.log(`Environment: ${process.env.NODE_ENV}`);
		// console.log(this.$ssrContext);
		this.$logEvent("show_home");

		
	},
};
</script>
