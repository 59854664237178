import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
const loadimage = require('@/assets/news/loading.png')
import '@/utlis/vant'
import '@/utlis/remcommon.js'
import shell from '@/utlis/shell.js'
import global from '@/utlis/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
const firebaseConfig = shell.firebaseConfig
let app = null
let analytics = null
isSupported().then((result) => {
	if (result) {
		app = initializeApp(firebaseConfig)
		analytics = getAnalytics(app)

		logEvent(analytics, 'in_page')
		console.log('in_page')
	}
})
Vue.prototype.$logEvent = (event, params = {}) => {
	// logFirebaseEvent(event, params) // 使用分割后的 Firebase 函数
	console.log(event)
	isSupported().then((result) => {
		if (result) {
			logEvent(analytics, event, params)
		}
	})
}
Vue.prototype.$eventrackFb = (msg, method, map = {}) => {
	let params = {
		time: new Date(),
		message: msg,
		method: method,
		...map,
	}
	console.log(msg)
	isSupported().then((result) => {
		if (result) {
			logEvent(analytics, msg, params)
		}
	})
}
// simple event

Vue.prototype.$global = global
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})

export default function createApp() {
	const router = createRouter()
	// 同步路由状态(route state)到 store
	// sync(router)
	router.afterEach((to, from, next) => {
		if (typeof document !== 'undefined') document.title = to.meta.title || `News`
	})
	const app = new Vue({
		router,
		data() {
			return {
				baseInfo: null,
				shell:shell,
			}
		},
		mounted() {
			const script = document.createElement('script')
			script.async = true
			script.src = shell.scriptUrl
			script.crossOrigin = 'anonymous'
			// script.setAttribute('data-ad-channel', cid);
			document.head.appendChild(script)
		},
		render: (h) => h(App),
	})
	return {
		app,
		router,
	}
}
